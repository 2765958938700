<template>
  <div id="PermissionSettings">
    <content-title :nav="nav"></content-title>
    <el-button
      style="color: #ffffff; background-color: #ff7e00; margin-bottom: 20px"
      @click="openAdd"
      >新增权限</el-button
    >

    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark">
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="descr" label="权限名"> </el-table-column>
      <el-table-column prop="create_time" label="添加时间"></el-table-column>
      <el-table-column prop="status" label="状态"></el-table-column>
      <el-table-column prop="name" label="操作">
        <template slot-scope="{row}">
          <samp style="color: #ff7e00; margin-right: 10px" @click="openEdit(row)">修改权限</samp>
          <el-popconfirm
  title="这是一段内容确定删除吗？"
  @confirm="delPermission(row)"
>
<samp style="color: #f56c6c" slot="reference" >删除</samp>
</el-popconfirm>
          
        </template>
      </el-table-column>
    </el-table>
    <!--新增  -->
    <el-dialog :visible.sync="dialogVisible" width="600px" title="新增">
      <div style="padding-right: 20px">
        <el-form  :model="form" class="demo-form-inline">
          <el-form-item label="权限名称" required>
            <el-input v-model="form.descr" placeholder="权限名称" class="input-width"></el-input>
          </el-form-item>
          <el-form-item label="权限接口" required>
            <el-input v-model="form.url" placeholder="权限接口" class="input-width"></el-input>
          </el-form-item>
          <el-form-item label="所属菜单">
            <el-select v-model="form.menu_id" placeholder="所属菜单" style="width:400px">
              <el-option
              v-for="menu in menuList"
              :key="menu.id"
               :label="menu.name" :value="menu.id" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button
              style="background-color: #ff7e00; color: #ffffff"
              v-if="type=='add'"
              @click="addPermission"
              >确 定</el-button
            >
            <el-button
               v-if="type=='edit'"
              style="background-color: #ff7e00; color: #ffffff"
              @click="updatePermission"
              >修 改</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 分页 -->
    <paging-fy
      @currentPageChange="currentPageChange"
      :currentPage="currentPage"
      :total="total"
    ></paging-fy>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      total: 0,
      dialogVisible: false,
      form: {},
      nav: { firstNav: "权限管理中心", secondNav: "权限设置" },
      tableData: [],
      data: [ ],
      menuList:[],
      defaultProps: {
        children: "children",
        label: "label",
      },
      type:'add'
    };
  },
  created() {
    this.getList();
    this.getMenu()
    this.$store.dispatch('getUserPermission')
  },

  methods: {
    openEdit(row){
      this.form = row
      this.dialogVisible = true
      this.type='edit'
    },
    openAdd(){
      this.dialogVisible = true
      this.type='add'
    },
    getList() {
      let params = {
        currentPage: this.currentPage,
        pageSize: 10,
        status:2,
        types: 3,
      };
      this.$axios({
        url: "authorization/apiUrl/queryManagerListPage",
        method: "get",
        params,
      }).then((res) => {
        this.tableData = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },
    getMenu() {
      let params = {
        currentPage: this.currentPage,
        pageSize: 30,
        type:'haima_houtai',
        parent_id:0,
        status:2
      };
      this.$axios({
        url: "authorization/menu/queryManagerListPage",
        method: "get",
        params,
      }).then((res) => {
        this.menuList = res.data.data.rows;
      });
    },
    updatePermission(){
      let data = {
        id:this.form.id,
        "descr": this.form.descr,
        "add_user": localStorage.getItem('userID'),
        "url": this.form.url,
        "menu_id": this.form.menu_id
      };
      this.$axios({
        url: "/authorization/apiUrl/update",
        method: "post",
        data,
      }).then((res) => {
        this.getList()
        this.$message({message:res.data.message})
        this.dialogVisible = false
      });
    },
    delPermission(row){
      let data = {
        id:row.id,
       status:1
      };
      this.$axios({
        url: "/authorization/apiUrl/update",
        method: "post",
        data,
      }).then((res) => {
        this.getList()
        this.$message({message:res.data.message})
      });
    },
    addPermission(){
      if(!this.form.descr || !this.form.descr){
          this.$message({message:'请填写权限名称和接口'})
      }
      let data = {
        "descr": this.form.descr,
        "add_user": localStorage.getItem('userID'),
        "parent_id": "0",
        "seq": "1",
        "url": this.form.url,
        "amount": 0,
        "opend": true,
        "types": "3",
        "authorize_account": true,
        "menu_id": this.form.menu_id
      };
      this.$axios({
        url: "/authorization/apiUrl/insert",
        method: "post",
        data,
      }).then((res) => {
        this.getList()
        this.$message({message:res.data.message})
        this.dialogVisible = false
      });
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getList();
    },
    handleChanges(data, checked, indeterminate) {
      if (checked) {
        console.log(data);
      }
    },
  },
};
</script>

<style lang="less">
#PermissionSettings {
  background-color: #ffffff;
  padding: 0 20px;
}

.cell {
  text-align: center;
}
.input-width{
  width: 400px !important;
}
</style>
